import axios from "axios";
import Config from "@/Configuration/Config";

export default {
    allUrl: Config.apiBaseUrl + "api/Link/GetAll",
    removeUrl: Config.apiBaseUrl + "api/Link/Remove",
    duplicateUrl: Config.apiBaseUrl + "api/QRCode/Duplicate",
    addOrUpdateUrl: Config.apiBaseUrl + "api/Link/AddOrUpdate",
    getUrl: Config.apiBaseUrl + "api/Link/GetLink?linkId=",
    checkSlugUrl :Config.apiBaseUrl +"api/Link/CheckSlug?slug=",
    uploadImageUrl: Config.apiBaseUrl+"api/Link/UploadImage",
    isSlugExistsUrl :Config.apiBaseUrl +"api/Link/SlugExists?slug=",
    getAll(filter) {
        return axios.post(this.allUrl, filter)
    },
    duplicate(qrCode) {
        return axios.post(this.duplicateUrl, qrCode)
    },
    addOrUpdate(qrCode) {
        return axios.post(this.addOrUpdateUrl, qrCode)
    },
    getLink(id){
        return axios.post(this.getUrl+id);
    },
    remove(id) {
        const fd = new FormData();
        fd.append("linkId", id);
        return axios({
            method: "post",
            url: this.removeUrl,
            data: fd,
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: "application/json",
            },
        });
    },
    checkSlug(slug,linkId){
        return axios.get(this.checkSlugUrl+slug+"&linkId="+linkId)
    },
    uploadImage(model){
        return axios.post(this.uploadImageUrl, model);
    },
    slugExists(slug){
        return axios.get(this.isSlugExistsUrl+slug)
    }
}