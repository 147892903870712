import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'remixicon/fonts/remixicon.css'
import axios from "axios";
import Config from "@/Configuration/Config";

// Appex Charts
import VueApexCharts from "vue3-apexcharts";

store.state.LoggedIn = sessionStorage.getItem("LoggedIn");
axios.defaults.baseURL = Config.apiBaseUrl;
axios.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("token");

// Auto Logout
const timeoutDuration = 10 * 60 * 1000; // 10 minutes
let timeoutId;

const logoutUser = () => {
    console.log("User logged out due to inactivity.");
    axios.defaults.headers.common["Authorization"] = null;
    sessionStorage.clear();
    localStorage.clear();
    store.state.LoggedIn = false;
    router.push('/');
};

const startTimeout = () => {
    clearTimeout(timeoutId); // Clear existing timeout to avoid stacking
    timeoutId = setTimeout(logoutUser, timeoutDuration);
};

// Reset the timer when the user interacts with the app
const resetTimeout = () => {
    startTimeout(); // Reset and restart the timer
};

window.addEventListener('mousemove', resetTimeout);
window.addEventListener('keypress', resetTimeout);
window.addEventListener('scroll', resetTimeout);

// Start the initial timeout
startTimeout();

createApp(App).use(store).use(ElementPlus).use(VueApexCharts).use(router).mount('#app');
