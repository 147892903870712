<template>
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md-8">
        <h3 class="text-uppercase">Add Link Rotator</h3>
      </div>
      <div class="col-12 col-md-4 d-flex justify-content-end">
        <router-link to="links">
          <el-button text bg class="me-2">
            <i class="bi bi-arrow-left-short me-2"></i>
            Cancel
          </el-button>
        </router-link>
        <el-button
          v-if="Link.linkId < 1"
          :disabled="!Link.title || Link.weights < 1"
          type="primary"
          @click="generateByteArray()"
          >Add Link</el-button
        >
        <el-button
          v-else
          type="primary"
          :disabled="!Link.title || Link.weights < 1"
          @click="generateByteArray()"
          >Update Link</el-button
        >
      </div>
    </div>
    <el-divider class="mt-1"></el-divider>
    <div class="col-12 col-md-5">
      <el-form label-position="top" v-model="Link">
        <el-form-item>
          <template #label>
            <span class="required">Campaign Name</span>
          </template>
          <el-input v-model="Link.title"></el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="">Domain / Slug</span>
          </template>
          <el-input
            :disabled="Link.linkId > 0"
            v-model="Link.linkGuid"
            @keyup="updateQrcode = new Date()"
          >
            <template #prepend>
              <el-select
                :disabled="Link.linkId > 0"
                style="width: 250px"
                v-model="Link.customUrl"
                @change="updateQrcode = new Date()"
              >
                <el-option
                  v-for="domain in Domains"
                  :key="domain.id"
                  :label="domain.name"
                  :value="domain.name"
                ></el-option>
              </el-select>
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <template #label>
            <span class="">Select Pixel</span>
          </template>
          <el-select
            multiple
            v-model="Link.pixelCodes"
            placeholder="Select pixel"
           value-key="id"
          >
            <el-option
              v-for="code in PixelCodes"
              :key="code.id"
              :label="code.codeName"
              :value="code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <template #label>
            <div class="d-flex justify-content-between">
              <span class="">Urls</span>
              <el-button
                type="primary"
                circle
                @click="addKeywordDialog = !addKeywordDialog"
                >+</el-button
              >
            </div>
          </template>
          <el-table
            :data="Link.weights"
            :fit="true"
            max-height="80vh"
            class="cstm-table"
            header-cell-class-name="tbl-header"
            size="small"
            stripe
          >
            <el-table-column label="Url" prop="keyword"></el-table-column>
            <el-table-column label="Weight" prop="weight"></el-table-column>
            <el-table-column width="55">
              <template #default="scope">
                <i
                  class="mgc_delete_2_line text-danger action-icon"
                  @click="removeKeyword(scope.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog
      v-model="addKeywordDialog"
      width="400"
      title="Add Link"
      draggable
    >
      <el-form label-position="top">
        <el-form-item label="Url">
          <el-input
            v-model="editedKeyword.keyword"
            placeholder="e.g https://www.google.com"
          ></el-input>
        </el-form-item>
        <el-form-item label="Weight">
          <template #label>
            <span>Weight </span><span class="text-caption">(e.g 10)</span>
          </template>
          <el-input
            v-model="editedKeyword.weight"
            placeholder="e.g 10"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="d-flex justify-content-end">
          <el-button text bg @click="addKeywordDialog = false"
            >Cancel</el-button
          >
          <el-button
            :disabled="!editedKeyword.keyword || editedKeyword.weight < 1"
            type="primary"
            @click="addKeyword()"
            >Add
          </el-button>
        </div>
      </template>
    </el-dialog>

    <div class="d-none">
      <div ref="qrcodeContainer">
        <QRCodeVue3
          ref="qrcode"
          :width="150"
          :height="150"
          :value="Link.customUrl + Link.linkGuid"
          :key="updateQrcode"
          :qrOptions="{
            typeNumber: 0,
            mode: 'Byte',
            errorCorrectionLevel: 'H',
          }"
          :imageOptions="{
            hideBackgroundDots: true,
            imageSize: 0.4,
            margin: 0,
          }"
          :dotsOptions="{
            type: 'dots',
            color: '#26249a',
            gradient: {
              type: 'linear',
              rotation: 0,
              colorStops: [
                { offset: 0, color: '#000' },
                { offset: 1, color: '#000' },
              ],
            },
          }"
          v-bind:image="Link.logo"
          :backgroundOptions="{ color: '#ffffff' }"
          :cornersSquareOptions="{ type: 'extra-rounded', color: '#000000' }"
          :cornersDotOptions="{ type: 'sqaure', color: '#000000' }"
          fileExt="png"
          myclass="my-qur"
          imgclass="img-qr"
          downloadButton="btn btn-primary"
          :downloadOptions="{ name: 'vqr', extension: 'webp' }"
        />
      </div>
    </div>
    <div v-if="loading" class="spinner-border d-loader"></div>
  </div>
</template>
<script>
import Config from "@/Configuration/Config";
import domainService from "@/Services/domainService";
import pixelCodeService from "@/Services/pixelCodeService";
import qrCodeService from "@/Services/linkService";
import linkService from "@/Services/linkService";
import store from "@/store";
import { ShowMessage } from "@/Utility/Utility";
import QRCodeVue3 from "qrcode-vue3";

export default {
  name: "AddLink",
  components: { QRCodeVue3 },
  data() {
    return {
      loading: false,
      addKeywordDialog: false,
      updateQrcode: new Date(),
      editedKeyword: {
        keyword: "",
        weight: 0,
      },
      LocalPixelCodes: [],
      PixelCodes: [],
      Domains: [],
      Countries: [
        { country: "United States", value: "com" },
        { country: "Canada", value: "ca" },
        { country: "Mexico", value: "com.mx" },
        { country: "United Kingdom", value: "co.uk" },
        { country: "Germany", value: "de" },
        { country: "Spain", value: "es" },
        { country: "France", value: "fs" },
        { country: "India", value: "co.in" },
        { country: "Italy", value: "it" },
        { country: "Japan", value: "co.jp" },
      ],
      Link: {
        linkId: 0,
        userId: sessionStorage.getItem("userId"),
        linkGuid: "",
        title: "",
        imageLink: "",
        linkType: 30,
        weights: [],
        link: "",
        logo: "",
        image: "",
        customUrl: Config.apiBaseUrl,
        trackedUrl: "",
        pixelCode: "",
        pixelCodes: [],
        /* domainName: "https://mglz.us",
         name: "https://mglz.us",*/
        // domainName: "https://mglz-dev.azurewebsites.net",
        // name: "https://mglz-dev.azurewebsites.net",
        domainName: Config.apiBaseUrl,
        name: Config.apiBaseUrl,
      },
      filter: {
        pageSize: 25,
        page: 1,
        filterText: "",
        isVerified: true,
        userId: sessionStorage.getItem("userId"),
        isActive: true,
      },
    };
  },
  methods: {
    isBase64Image(imageUrl) {
      // Check if the image URL starts with the specified base64 prefix
      return imageUrl.startsWith("data:image/jpeg;base64,");
    },
    async generateByteArray() {
      try {
        this.loading = true;
        if (!this.Link.title) {
          ShowMessage("error", "Please add a title");
          this.loading = false;
          return;
        }
        if (this.Link.weights.length < 1) {
          ShowMessage("error", "Please add one url atleast");
          this.loading = false;
          return;
        }
        if (this.Link.linkId < 1) {
          if (this.Link.linkGuid) {
            let slugResponse = await qrCodeService.slugExists(
              this.Link.linkGuid
            );
            if (slugResponse.data) {
              this.loading = false;
              ShowMessage("error", "Slug already exists");
              return;
            }
          } else {
            let response = await qrCodeService.checkSlug(
              this.Link.linkGuid,
              this.Link.linkId
            );
            this.Link.linkGuid = response.data;
          }
        }
        this.updateQrcode = new Date();

        // Wait for the QR code generation to complete
        await this.$nextTick();

        // Fetch the image source after waiting for the asynchronous operations
        await new Promise((resolve) => setTimeout(resolve, 0)); // This micro-task ensures the next tick
        const imgSrc = document.querySelector(".img-qr").getAttribute("src");

        let model = {
          linkGuid: this.Link.linkGuid,
          qrCode: imgSrc,
        };
        this.Link.imageLink = await this.uploadImage(model);
        //this.Link.qrCode = imgSrc;
        // Now call the CreateURL method
        await this.CreateCampaign();
      } catch (error) {
        console.error("Error generating Base64 string:", error);
      }
    },
    async uploadImage(model) {
      try {
        let response = await qrCodeService.uploadImage(model);
        return response?.data ?? "";
      } catch (e) {
        ShowMessage("error", "QrCode creation failed");
      }
    },
    addKeyword() {
      this.Link.weights.push(this.editedKeyword);
      this.editedKeyword = {
        keyword: "",
        weight: 0,
      };
      this.addKeywordDialog = false;
    },
    removeKeyword(row) {
      const index = this.Link.weights.indexOf(row);
      if (index !== -1) {
        this.Link.weights.splice(index, 1);
      }
    },
    CheckPixel() {
      this.Link.pixelCodes = [];
      this.LocalPixelCodes.forEach((element) => {
        let object = {
          pixelId: element.id,
        };
        this.Link.pixelCodes.push(object);
      });
    },
    async GetDomains() {
      try {
        this.loader = true;
        let response = await domainService.all(this.filter);
        this.Domains = response.data.data;
        this.loader = false;
      } catch (e) {
        this.loader = false;
        console.log(e);
      }
    },
    async GetPixels() {
      try {
        this.loader = true;
        let response = await pixelCodeService.getAll(this.filter);
        this.PixelCodes = response.data.data;
        this.loader = false;
      } catch (e) {
        console.log(e);
      }
    },
    async CreateCampaign() {
      try {
        this.loading = true;
        if (this.Link.customUrl === null || this.Link.customUrl === "") {
          this.Link.customUrl = Config.apiBaseUrl;
        }
        // const lastChar = this.Link.customUrl.slice(-1);
        // if(lastChar === '/'){
        //   this.Link.customUrl = this.Link.customUrl.replace(/\/$/, "");
        // }
        if (
          !this.Link.customUrl.startsWith("https://") &&
          !this.Link.customUrl.startsWith("http://")
        ) {
          // If not, add "https://" to the beginning of the string
          this.Link.customUrl = "https://" + this.Link.customUrl;
        }
        if (this.Link == null) return;
    
        let response = await linkService.addOrUpdate(this.Link);
        this.loading = false;

        if (response.data.isSuccessful === true) {
          if (this.Link.linkId > 0) {
            ShowMessage("success", "Link updated successfully!");
          } else {
            ShowMessage("success", "Link created successfully!");
          }
          this.clearForm();
        }
      } catch (error) {
        this.loading = false;
        ShowMessage("error", "Something went wrong!");
      }
    },
    clearForm() {
      this.Link = {
        linkId: 0,
        title: "",
        asin: "",
        marketPlace: "",
        linkGuid: "",
        weights: [],
        userId: localStorage.getItem("userId"),
        linkType: 20,
        pixelCode: "",
        domainName: Config.apiBaseUrl,
        customUrl: Config.apiBaseUrl,
      };
      this.LocalPixelCodes = [];
    },
    async GetLink() {
      try {
        this.loading = true;
        let res = await qrCodeService.getLink(store.state.pageId);
        this.Link = res.data.data;
        if (this.Link.pixelCodes != null && this.Link.pixelCodes.length > 0) {
          this.Link.pixelCodes.forEach((element) => {
            this.LocalPixelCodes.push(element);
          });
        }

        this.loading = false;
        this.updateQrcode = new Date();
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
  },
  created() {
    if (store.state.pageId > 0) {
      this.GetLink();
    }
    this.GetPixels();
    this.GetDomains();
  },
};
</script>
<style scoped></style>
